import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    show: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    }
  },
  components: {
    Paginate
  },
  data() {
    return {
      busy: false,
      totalItem: 0,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortable: true,
      fields: [
        {
          key: "index",
          label: "#",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "document_no",
          label: "เลขที่ใบส่งสินค้า",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "bill.bill_customer_name",
          label: "ชื่อลูกค้า",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "bill.bill_customer_shipping_address",
          label: "ที่อยู่",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "creator_detail.name",
          label: "ผู้ออกเอกสาร",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "total_price",
          label: "ยอดสุทธิ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        },
        { 
          key: 'status', 
          label: 'สถานะ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        }
      ],
      items: [],
     }
  },
  computed: {
    rows() {
      return this.items.length
    },
  },
  watch: {
    show: function (newValue, oldValue) {
      if (newValue) {
        this.loadItems(this.uuid)
      }
    },
  },
  methods: {
    onClose () {
      this.$emit('onPopupCloseHandler', {
        status: false
      })
    },
    async loadItems(q) {
      this.loading = true
      this.busy = true;
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/delivery-bill/${q}`
        );
        if (result) {
          const data = result.data;

          this.items = data;
          this.totalItem = data.length

          console.log(this.items)
        }
        this.loading = false
        this.busy = false;
      } catch (error) {
        this.loading = false
        this.busy = false;
        this.onExceptionHandler(error.response.data.message);
      }
    },
  }
}
