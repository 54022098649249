import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Loading from '@/components/widgets/Loading.vue'
import Paginate from "@/components/widgets/pagination.vue";
import Popup from "../item-list-for-deliver-popup";
import Popup2 from "../delivery-documents-popup";
import moment from 'moment'
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})
export default {
  components: {
    DatePicker,
    Loading,
    Paginate,
    Popup,
    Popup2
  },
  data: function () {
    return {
      products: [],
      isShowingSelectProductForDelivery: false,
      isShowingDeliveryDocumentList: false,
      selectUUID: "",
      role: "",
      branch: "",
      loading: false,
      busy: false,
      apiStatus: 'ไม่พบข้อมูล',
      totalItem: 0,
      keyword_status: null,
      doc: {
        start: null,
        end: null
      },
      duedate: {
        start: null,
        end: null
      },
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      keyword: '',
      fields: [
        {
          key: 'bill_no',
          label: 'เลขใบรับคำสั่งซื้อ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'ลงวันที่',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'bill_shipping_date',
          label: 'จัดส่ง',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'customer',
          label: 'ชื่อลูกค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'sale_total',
          label: 'ยอดคำสั่งซื้อสุทธิ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right',
          sortable: true
        },
        {
          key: 'bill_grand_total',
          label: 'ยอดคงค้างสุทธิ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right',
          sortable: true
        },
        {
          key: 'bill_seller',
          label: 'ผู้ขาย',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'bill_note',
          label: 'หมายเหตุ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-left',
          sortable: true
        },
        {
          key: 'status',
          label: 'สถานะการส่งสินค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'action',
          label: '#',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right'
        }
      ],
      items: []
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    async getRole() {
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
    },
    onallowedit(id) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
          '<div class="d-flex flex-column">' +
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการเปิดการแก้ไขใบรับคำสั่งซื้อนี้หรือไม่?</span>' +
          '<span class="text-base text-left text-gray-500">หากคุณยืนยันแล้ว จะเป็นการเปิดการแก้ไขใบรับคำสั่งซื้อได้ 1 ครั้ง?</span>' +
          '</div>' +
          '</div>',
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          popup: 'swal2-custom-rounded',
          closeButton: 'text-3xl swal2-custom-close',
          actions: 'justify-content-end',
          contant: 'd-flex flex-row justify-content-around px-2',
          title: 'd-none',
          confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
        },
        cancelButtonColor: '#dc4a38',
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: "ตกลง",
        showCancelButton: true,
        showCloseButton: true,
      }).then(async confirm => {
        if (confirm.value) {
          this.loading = true
          this.onConfirmEdit(id)
        }
      })
    },
    ondeleted(id) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
          '<div class="d-flex flex-column">' +
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการจะยกเลิกใบรับคำสั่่งซื้อนี้หรือไม่?</span>' +
          '<span class="text-base text-left text-gray-500">หากคุณยืนยันแล้ว ใบเสนอราคาที่เกี่ยวข้องกับใบรับคำสั่งซื้อนี้และลีด(ถ้ามี) จะถูกยกเลิกไปด้วยและไม่สามารถย้อนกลับได้?</span>' +
          '</div>' +
          '</div>',
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          popup: 'swal2-custom-rounded',
          closeButton: 'text-3xl swal2-custom-close',
          actions: 'justify-content-end',
          contant: 'd-flex flex-row justify-content-around px-2',
          title: 'd-none',
          confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
        },
        cancelButtonColor: '#dc4a38',
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: "ตกลง",
        showCancelButton: true,
        showCloseButton: true,
      }).then(async confirm => {
        if (confirm.value) {
          this.loading = true
          this.onConfirmDelete(id)
        }
      })
    },
    async onConfirmDelete(id) {
      try {
        const param = {
          id: id
        }
        this.loading = true
        const result = await this.$store.dispatch('Bills/delete', param, { root: true })
        setTimeout(() => {
          this.loading = false
          if (result.status === 204) {
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
                '<div class="d-flex flex-column">' +
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>' +
                '<span class="text-base text-left text-gray-500">ยกเลิกเอกสารเรียบร้อยแล้ว</span>' +
                '</div>' +
                '</div>',
              allowOutsideClick: false,
              focusConfirm: false,
              customClass: {
                popup: 'swal2-custom-rounded',
                closeButton: 'text-3xl swal2-custom-close',
                actions: 'justify-content-end',
                contant: 'd-flex flex-row justify-content-around px-2',
                title: 'd-none',
                confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
              },
              cancelButtonColor: '#dc4a38',
              cancelButtonText: 'ยกเลิก',
              confirmButtonText: "ตกลง",
              showCancelButton: false,
              showCloseButton: true,
            }).then(async confirm => {
              if (confirm.value) {
                this.onInitData(this.currentPage)
              }
            })
          }
        }, 1000)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async onConfirmEdit(id) {
      try {
        const param = {
          id: id
        }
        this.loading = true
        const result = await Vue.prototype.$axios.post(
          `${Vue.prototype.$host}/api/v1/enableBillEditing/${id}`
        );
        setTimeout(() => {
          this.loading = false
          if (result.status === 200) {
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
                '<div class="d-flex flex-column">' +
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>' +
                '<span class="text-base text-left text-gray-500">เปิดให้แก้ไขเอกสารแล้ว</span>' +
                '</div>' +
                '</div>',
              allowOutsideClick: false,
              focusConfirm: false,
              customClass: {
                popup: 'swal2-custom-rounded',
                closeButton: 'text-3xl swal2-custom-close',
                actions: 'justify-content-end',
                contant: 'd-flex flex-row justify-content-around px-2',
                title: 'd-none',
                confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
              },
              cancelButtonColor: '#dc4a38',
              cancelButtonText: 'ยกเลิก',
              confirmButtonText: "ตกลง",
              showCancelButton: false,
              showCloseButton: true,
            }).then(async confirm => {
              if (confirm.value) {
                this.onInitData(this.currentPage)
              }
            })
          }
        }, 1000)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    oncreate() {
      this.$router.push("/billing/create")
    },
    onInfo(id) {
      this.$emit('componentInfo', {
        status: true,
        component: 'billing-info'
      })
    },
    onedit(uuid) {
      const routeData = this.$router.resolve("/billing/create/" + uuid);
      window.open(routeData.href, "_blank");
    },
    async onInitData(currentPage) {
      this.currentPage = currentPage
      try {
        // this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'

        const page = currentPage ? currentPage : 1
        const url = `${Vue.prototype.$host}/api/v1/bills?page=${page}&perPage=8&keyword=${this.keyword}&keyword_status=${this.keyword_status != null ? this.keyword_status : ''}
        &keyword_startdate=${this.doc.start ? moment(this.doc.start).format('YYYY-MM-DD') : ''}&keyword_enddate=${this.doc.end ? moment(this.doc.end).format('YYYY-MM-DD') : ''}
        &keyword_start_shipdate=${this.duedate.start ? moment(this.duedate.start).format('YYYY-MM-DD') : ''}&keyword_end_shipdate=${this.duedate.end ? moment(this.duedate.end).format('YYYY-MM-DD') : ''}`
        const param = {
          keyword: this.keyword,
          url: url
        }
        const result = await this.$store.dispatch('Bills/getAll', param, { root: true })
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'

          if (result) {
            this.currentData = result.data;
            this.items = result.data.data
            this.totalItem = result.data.total
          } else {
            this.items = []
            this.totalItem = 0
          }
        }, 500)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onPrintProductionHandler() {
      this.$emit('componentInfo', {
        status: true,
        component: 'production'
      })
    },
    generateQRcode(uuid) {
      try {
        this.loading = true
        setTimeout(async () => {
          const result = await this.$axios.get(
            this.$host + "/api/v1/downloadBillPDF/" + uuid,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              responseType: "blob"
            }
          )
          const blob = new Blob([result.data], { type: 'application/pdf' });
          const objectUrl = URL.createObjectURL(blob)
          window.open(objectUrl, 'targetWindow',
            `toolbar=no,
          location=no,
          status=no,
          menubar=no,
          scrollbars=yes,
          resizable=yes,
          width=SomeSize,
          height=SomeSize`);

          // const link = document.createElement("a");
          // link.href = objectUrl;
          // this.pdfsrc = objectUrl
          // link.setAttribute(
          //   "download",
          //   `ใบเสนอราคา-${name}.pdf`
          // );
          // document.body.appendChild(link);
          // link.click();

          // URL.revokeObjectURL(objectUrl)
          this.loading = false
        }, 1000)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onCreateDeliveryDocument(e) {
      this.selectUUID = ""
      this.isShowingSelectProductForDelivery = false

      if (e.status) {
        const productsWithoutHeader = e.data.filter(key=>key.type === 1)
        if (productsWithoutHeader.length === 0) {
          this.$swal({
            title: 'แจ้งเตือน',
            type: 'error',
            html:
              'กรูณาเลือกสินค้าอย่างน้อย 1 ชิ้น เพื่อสร้างใบส่งสินค้า',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText:
              'ตกลง',
            confirmButtonAriaLabel: 'OK',
          })
        }
        else {
          this.createDeliveryDocument(e)
        }
      }
    },
    onOpenPopup(uuid) {
      this.selectUUID = uuid
      this.isShowingSelectProductForDelivery = true
    },
    onOpenPopup2(uuid) {
      this.selectUUID = uuid
      this.isShowingDeliveryDocumentList = true
    },
    onConvertToProduction(uuid) {
      this.$router.push({ path: 'production/create', query: { q: uuid } })
    },
    async createDeliveryDocument(e) {
      const items = e.data
      for (let index = 0; index < items.length; index++) {
        const element = items[index]
        this.products.push(element)
      }

      const formData = new FormData()
      formData.append('products', JSON.stringify(this.products))
      formData.append('show_price', e.show_price)
      formData.append('remark', e.remark)

      formData.append('subtotal', e.subtotal)
      formData.append('discount', e.discount)
      formData.append('before_vat', e.before_vat)
      formData.append('vatable', e.vatable)
      formData.append('vat', e.vat)
      formData.append('deposit', e.deposit)
      formData.append('total_price', e.total)
      formData.append('is_complete_delivery', e.is_complete_delivery)

      try {
        this.loading = true;
        const result = await Vue.prototype.$axios.post(
          `${Vue.prototype.$host}/api/v1/document/delivery/${e.uuid}`
        , formData)

        this.loading = false;
        if (result.status === 201) {
          this.$swal({
            title: 'สร้างใบส่งสินค้าสำเร็จ',
            type: 'success',
            html:
              'ระบบจะพาท่านไปยังเมนูใบส่งสินค้า',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText:
              'ตกลง',
            confirmButtonAriaLabel: 'OK',
          }).then(() => {
            this.$router.push('/delivery')
          })
        }
      } catch (error) {
        this.loading = false;
        let message = error.response
          ? error.response.data.message
          : error.message;
        this.onExceptionHandler(message);
      }
    }
  },
  mounted() {
    this.getRole()
    this.onInitData(this.currentPage)
  }
}
